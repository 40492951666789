import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import {
  EndChapter,
  ExplorePage,
  Header,
  MainLoading,
  MonCharacter,
  StartChapter,
} from "./common/components";
import "./styles/common.css";
import { initialize, landingStage } from "./utils/common";
function App() {
  const [observerRef, observerInView] = useInView();

  useEffect(() => {
    initialize();
  }, []);
  useEffect(() => {
    if (observerInView) {
      landingStage();
    }
  }, [observerInView]);
  return (
    <div id="container" className="container">
      <Header />
      <MainLoading />
      {/* <!-- #content --> */}
      <div className="cache"></div>
      <main id="content" className="content main-content">
        <MonCharacter />
        <ExplorePage />
        <div className="stage">
          <StartChapter />
          <div className="counter">0</div>
          <div ref={observerRef} style={{ position: "absolute", bottom: 1 }} />
        </div>
        <EndChapter />
        <div className="fog">
          <div className="wrapper">
            <canvas></canvas>
          </div>
        </div>
      </main>
    </div>
  );
}

export default App;
