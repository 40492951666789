import { memo } from "react";

const ExplorePage = () => {
  return (
    <div className="page-content">
      <div className="section section1">
        <div className="wrapper">
          <span className="text text1">
            You’ve <span>safely</span>
          </span>
          <span className="text text2">landed in</span>
          <span className="text text3">Another World</span>
        </div>
      </div>
      <div className="section section2">
        <div className="wrapper">
          welcome! We are about to go on <br className="pc" />
          a little journey. <br className="pc" />
          Your only job is to enjoy the scenery <br className="pc" />
          unfolding in <br className="mo" /> front of your eyes.
        </div>
      </div>
      <div className="section section3">
        <div className="wrapper">
          take a deep breath, <br className="pc" />
          and scroll!
        </div>
      </div>

      <div className="gap" />
    </div>
  );
};

export default memo(ExplorePage);
