import gsap from "gsap";
import { isMobile } from "react-device-detect";

/*! IE-Checker v1.0.0 | (c) 2007-2015 The GrotesQ | https://github.com/Unk/IE-Checker */
// !(function () {
//   var e = navigator.userAgent.toLowerCase();
//   if (-1 != e.indexOf("msie") || -1 != e.indexOf("trident")) {
//     var a = 11;
//     (e = /msie ([0-9]{1,}[\.0-9]{0,})/.exec(e)), e && (a = parseInt(e[1]));
//     var i = "";
//     (i += " is-ie"), (i += " ie" + a);
//     for (var t = a + 1; 11 >= t; t++) i += " lt-ie" + t;
//     document.getElementsByTagName("html")[0].className += i;
//   }
// })();

/* sound */

var startChk = false;

var bgm = new Audio("../static/sound/bgm.mp3");
var bgmVolume = 0.4;
var volObj = { x: 0 };
bgm.loop = true;
bgm.volume = volObj.x;
bgm.mute = true;
bgm.pause();

window.addEventListener("GAME_SOUND_MUTE", soundMuteToggler);
window.addEventListener("GAME_SOUND_START", soundStart);

function soundMuteToggler() {
  window.muted = !window.muted;
  if (window.muted) {
    document.querySelector("#header .sound-btn").classList.add("mute");

    gsap.to(volObj, {
      x: 0,
      duration: 1,
      ease: "none",
      onUpdate: function () {
        bgm.volume = volObj.x;
      },
      onComplete: function () {
        bgm.muted = true;
        bgm.pause();
      },
    });
  } else {
    document.querySelector("#header .sound-btn").classList.remove("mute");

    gsap.to(volObj, {
      x: bgmVolume,
      duration: 1,
      ease: "none",
      onStart: function () {
        bgm.play();
        bgm.muted = false;
      },
      onUpdate: function () {
        bgm.volume = volObj.x;
      },
    });
  }
}

function soundStart() {
  if (startChk) return;
  startChk = true;
  soundMuteToggler();
}

/**
 * ui Script
 * --------------------------------
 */

let w, h;
let initChk = false;

const loadArr = [];
let loadCount = 0;

let loadingFlag = false;

const mon = document.querySelector(".mon1 img");
let monCount = 0;
const monMaxCount = 100;
const monWidth = 500;
const monHeight = 600;
const monArr = [];
const stageName = "stage1";
const stageContainerArr = [];
const stageContainerLowerArr = [];
const stageSceneMaxCount = 1016;
const stageImgSrcArr = [];
const stageImgSrcHighArr = [];
const fogArr = [];
const fog1 = 330;
const fog2 = 710;
const fogLength = 70;
const endPoint = 946;

const chapterLength = 70;
const chapters = [
  {
    start: 30,
    end: 30 + chapterLength,
  },
  {
    start: 130,
    end: 130 + chapterLength,
  },
  {
    start: 230,
    end: 230 + chapterLength,
  },
  {
    start: 430,
    end: 430 + chapterLength,
  },
  {
    start: 530,
    end: 530 + chapterLength,
  },
  {
    start: 630,
    end: 630 + chapterLength,
  },
  {
    start: 830,
    end: 830 + chapterLength,
  },
  {
    start: endPoint,
    end: 200,
  },
];
const chapterPoint = 0.5;
let chapterNum = 1;
let chapterProgress = 0;

let stageCutNum = 0;
let oldCutNum = -1;
let fogNum = 0;
const tourPointObj = { t: 0 };

let standardRatioW, standardRatioH;

let speed = 0;
const velocity = 0.88;
const backBreak = 0.14;
let autoPlayChk = false;
let speedInterval;

let monCanvas;
let monContext;
let stageCanvas;
let stageContext;
let fogCanvas;
let fogContext;

const moImg = new Image();
let stageImgUpscale;

window.muted = true;

gsap.defaults({ ease: "power2.out", duration: 0.8 });

function setUnitProperty() {
  var windowWidth = document.querySelector("body").clientWidth,
    windowHeight = document.querySelector("body").clientHeight,
    vw = 0.01 * document.querySelector("body").clientWidth,
    vh = 0.01 * document.querySelector("body").clientHeight;
  w = windowWidth;
  h = windowHeight;
  document.documentElement.style.setProperty("--vw", "".concat(vw, "px"));
  document.documentElement.style.setProperty("--vh", "".concat(vh, "px"));
  document.documentElement.style.setProperty(
    "--fullwidth",
    "".concat(windowWidth, "px")
  );
  document.documentElement.style.setProperty(
    "--fullheight",
    "".concat(windowHeight, "px")
  );
  document.documentElement.style.setProperty(
    "--windowheight",
    "".concat(window.innerHeight, "px")
  );
  document.documentElement.style.setProperty(
    "--swipeX",
    "".concat(focusSlide(), "%")
  );
}

function focusSlide() {
  let ww = window.innerWidth;
  let wh = window.innerHeight;
  let maxGap;
  if (ww < wh) {
    maxGap = ((wh - ww) / wh) * 50;
    return maxGap * chapterProgress;
  } else {
    return 0;
  }
}

function moveSpeed() {
  tourPointObj.t = 0;

  if (speed > 0) {
    if (stageCutNum === fog1) {
      clearSpeed();
      autoPlayChk = true;
      tourPointObj.t = fog1;
      fogDrive(fog1 + fogLength);
    } else if (stageCutNum === fog2) {
      clearSpeed();
      tourPointObj.t = fog2;
      fogDrive(fog2 + fogLength);
    } else if (stageCutNum === endPoint) {
      clearSpeed();
      autoPlayChk = true;
      tourPointObj.t = endPoint;
      gsap.to(tourPointObj, {
        t: stageSceneMaxCount - 1,
        ease: "Sine.easeInOut",
        duration: 2.6,
        onUpdate: function () {
          if (oldCutNum === Math.floor(tourPointObj.t)) return;
          stageDraw(stageContainerArr, Math.floor(tourPointObj.t));
        },
        onComplete: function () {
          stageCutNum = stageSceneMaxCount - 1;
          autoPlayChk = false;
        },
      });
      gsap.to(".mon-wrapper", { opacity: 0, duration: 1.5, delay: 0.5 });
      gsap.fromTo(
        ".chapter-end .inner",
        { opacity: 0, yPercent: -30 },
        {
          opacity: 1,
          yPercent: 0,
          duration: 2,
          delay: 2,
          ease: "Cubic.easeOut",
          onStart: function () {
            document.querySelector(".chapter-end").style.display = "flex";
            document.querySelector(".chapter-end").classList.add("reset");
          },
        }
      );
      gsap.fromTo(
        ".chapter-end .footer",
        { opacity: 0, yPercent: 30 },
        {
          opacity: 1,
          yPercent: 0,
          duration: 1,
          delay: 4,
          ease: "Cubic.easeOut",
          onStart: function () {},
        }
      );
    } else {
      tourPointObj.t = stageCutNum;

      if (chapterNum === 3) {
        stageDrive(fog1, "front", true);
      } else if (chapterNum === 6) {
        stageDrive(fog2, "front", true);
      } else if (
        chapterNum === 7 &&
        stageCutNum > chapters[chapterNum - 1].start
      ) {
        stageDrive(endPoint, "front", true);
      } else {
        if (
          stageCutNum === 0 ||
          stageCutNum === fog1 + fogLength ||
          stageCutNum === fog2 + fogLength
        ) {
          stageDrive(
            chapters[chapterNum - 1].start + chapterLength * chapterPoint,
            "front",
            false
          );
        } else {
          stageDrive(
            chapters[chapterNum].start + chapterLength * chapterPoint,
            "front",
            false
          );
        }
      }
    }
  } else {
    if (stageCutNum === 0) {
      return;
    } else if (stageCutNum === fog1 + fogLength) {
      clearSpeed();
      tourPointObj.t = fog1 + fogLength;
      fogDrive(fog1);
    } else if (stageCutNum === fog2 + fogLength) {
      clearSpeed();
      tourPointObj.t = fog2 + fogLength;
      fogDrive(fog2);
    } else {
      tourPointObj.t = stageCutNum;

      if (chapterNum === 4) {
        stageDrive(fog1 + fogLength, "back", true);
      } else if (chapterNum === 7) {
        stageDrive(fog2 + fogLength, "back", true);
      } else if (chapterNum === 1) {
        stageDrive(0, "back", false);
      } else {
        if (stageCutNum === fog1 || stageCutNum === fog2) {
          stageDrive(
            chapters[chapterNum - 1].start + chapterLength * 0.5,
            "back",
            false
          );
        } else {
          stageDrive(
            chapters[chapterNum - 2].start + chapterLength * 0.5,
            "back",
            false
          );
        }
      }
    }
  }

  if (speed > 0) {
    speed = speed * velocity;
  } else {
    speed = speed * (velocity - backBreak);
  }

  if (Math.abs(speed) < 0.05) {
    clearSpeed();
  }

  function clearSpeed() {
    clearInterval(speedInterval);
    speed = 0;
  }
}

function monBounce() {
  gsap.to(".mon1", {
    duration: 2.8,
    repeat: -1,
    onUpdate: function () {
      // eslint-disable-next-line no-unused-expressions
      monCount !== Math.floor(monMaxCount * this.progress())
        ? monDraw(Math.floor(monMaxCount * this.progress()))
        : "";
    },
  });
}

function monDraw(num) {
  monCount = num;
  monCanvas.width = monWidth;
  monCanvas.height = monHeight;
  try {
    monContext.drawImage(
      monArr[num],
      0,
      0,
      500,
      600,
      0,
      0,
      monWidth,
      monHeight
    );
  } catch (error) {
    console.log(error);
  }
}

function stageDraw(stage, num) {
  clearTimeout(stageImgUpscale);

  if (isMobile && !stageImgSrcArr[num]) {
    return;
  } else if (!isMobile && !stage[num]) {
    return;
  }

  oldCutNum = num;

  if (stageImgSrcArr[num]) {
    if (
      moImg.src.split("images")[1] === stageImgSrcArr[num].split("images")[1]
    ) {
      if (isMobile) {
        canvasDraw(moImg);
      } else {
        canvasDraw(stage[num]);
      }
    } else {
      let img = new Image();
      if (isMobile) {
        if (stageContainerArr[num]) {
          canvasDraw(stageContainerArr[num]);
        } else if (stageContainerLowerArr[num]) {
          canvasDraw(stageContainerLowerArr[num]);
          stageImgUpscale = setTimeout(() => {
            img.addEventListener("load", () => {
              stageDraw(stageContainerArr, stageCutNum);
            });
            img.src = stageImgSrcHighArr[num];
            stageContainerArr[num] = img;
          }, 100);
        } else {
          moImg.src = stageImgSrcArr[num];
        }
      } else {
        img = stage[num];
        canvasDraw(img);
      }
    }
  }

  document.querySelector(".stage .counter").innerText = num;
}

function canvasDraw(i) {
  let pic;
  pic = i;

  const oriW = pic.naturalWidth;
  const oriH = pic.naturalHeight;
  let startX, startY;
  let bgW, bgH;
  let scaleRatio;

  const ww = window.innerWidth;
  const wh = window.innerHeight;
  stageCanvas.width = Math.max(ww, wh);
  stageCanvas.height = Math.max(ww, wh);

  if (ww > wh) {
    scaleRatio = wh / ww;
    bgW = oriW;
    bgH = oriH * scaleRatio;
    startX = 0;
    startY = (oriH - bgH) * 0.37;
  } else {
    scaleRatio = ww / wh;
    bgW = oriW * scaleRatio;
    bgH = oriH;
    startX = (oriW - bgW) * 0.5;
    startY = 0;
  }

  try {
    stageContext.drawImage(
      pic,
      startX,
      startY,
      bgW,
      bgH,
      0,
      0,
      stageCanvas.width,
      stageCanvas.height
    );
  } catch (error) {
    console.log(error);
  }
}

function stageDrive(goal, direction, again) {
  // console.log(tourPointObj.t, goal, chapterNum);
  autoPlayChk = true;
  gsap.to(tourPointObj, {
    t: goal,
    ease: "none",
    duration: Math.abs(goal - tourPointObj.t) * 0.048,
    onStart: function () {
      // document.querySelector('.fog').style.display = 'block';
    },
    onUpdate: function () {
      stageCutNum = Math.floor(tourPointObj.t);
      if (oldCutNum === stageCutNum) return;
      stageDraw(stageContainerArr, stageCutNum);
      chapterDraw(direction);
    },
    onComplete: function () {
      stageCutNum = tourPointObj.t;
      autoPlayChk = false;
      // fogNum
      if (again) moveSpeed();
    },
  });
}

function fogDrive(goal) {
  autoPlayChk = true;
  gsap.to(tourPointObj, {
    t: goal,
    ease: "none",
    duration: 3,
    onStart: function () {
      document.querySelector(".fog").style.display = "block";
    },
    onUpdate: function () {
      if (oldCutNum === Math.floor(tourPointObj.t)) return;
      stageDraw(stageContainerArr, Math.floor(tourPointObj.t));
      fogDraw(Math.floor(tourPointObj.t));
    },
    onComplete: function () {
      document.querySelector(".fog").style.display = "none";
      stageCutNum = tourPointObj.t;
      autoPlayChk = false;

      if (stageCutNum === fog1) {
        chapterNum = 3;
      } else if (stageCutNum === fog2) {
        chapterNum = 6;
      } else if (stageCutNum === fog1 + fogLength) {
        chapterNum = 4;
      } else if (stageCutNum === fog2 + fogLength) {
        chapterNum = 7;
      }
      // if (stageCutNum == fog1+fogLength || stageCutNum == fog2+fogLength) chapterNum++;
      // fogNum
    },
  });
}

function fogDraw(frame) {
  let num;

  if (fogNum === frame) return;
  fogNum = frame;
  // console.log(frame, num, fogNum);

  if (frame > fog1 && frame < fog1 + fogLength) {
    num = Math.floor(frame - fog1);
  } else if (frame > fog2 && frame < fog2 + fogLength) {
    num = Math.floor(frame - fog2);
  } else {
    return;
  }

  const ww = window.innerWidth;
  const wh = window.innerHeight;
  fogCanvas.width = ww;
  fogCanvas.height = wh;

  // console.log(num, fogArr[num]);

  const oriW = fogArr[num].naturalWidth;
  const oriH = fogArr[num].naturalHeight;
  let startX, startY;
  let bgW, bgH;
  let scaleRatio;

  if (ww > wh) {
    scaleRatio = wh / ww;
    bgW = oriW;
    bgH = oriH * scaleRatio;
    startX = 0;
    startY = (oriH - bgH) * 0.5;
    // console.log('가로', scaleRatio, startX, startY, bgW, bgH);
  } else {
    scaleRatio = ww / wh;
    bgW = oriW * scaleRatio;
    bgH = oriH;
    startX = (oriW - bgW) * 0.5;
    startY = 0;
    // console.log('세로', scaleRatio, startX, startY, bgW, bgH);
  }

  // context.drawImage(stage[num], posx, posy, canvas.width, canvas.height, 0, 0, canvas.width, canvas.height);
  try {
    fogContext.drawImage(
      fogArr[num],
      startX,
      startY,
      bgW,
      bgH,
      0,
      0,
      fogCanvas.width,
      fogCanvas.height
    );
  } catch (error) {
    console.log(error);
  }
}

function chapterDraw(direction) {
  let cNum;
  if (stageCutNum > chapters[0].start && stageCutNum < chapters[0].end) {
    cNum = 1;
    if (chapterNum !== cNum) {
      chapterNum = cNum;
    }
  } else if (stageCutNum > chapters[1].start && stageCutNum < chapters[1].end) {
    cNum = 2;
    if (chapterNum !== cNum) {
      chapterNum = cNum;
    }
  } else if (stageCutNum > chapters[2].start && stageCutNum < chapters[2].end) {
    cNum = 3;
    if (chapterNum !== cNum) {
      chapterNum = cNum;
    }
  } else if (stageCutNum > chapters[3].start && stageCutNum < chapters[3].end) {
    cNum = 4;
    if (chapterNum !== cNum) {
      chapterNum = cNum;
    }
  } else if (stageCutNum > chapters[4].start && stageCutNum < chapters[4].end) {
    cNum = 5;
    if (chapterNum !== cNum) {
      chapterNum = cNum;
    }
  } else if (stageCutNum > chapters[5].start && stageCutNum < chapters[5].end) {
    cNum = 6;
    if (chapterNum !== cNum) {
      chapterNum = cNum;
    }
  } else if (stageCutNum > chapters[6].start && stageCutNum < chapters[6].end) {
    cNum = 7;
    if (chapterNum !== cNum) {
      chapterNum = cNum;
    }
  }

  if (direction === "front") {
    // front
    if (
      stageCutNum >= chapters[chapterNum - 1].start &&
      stageCutNum <= chapters[chapterNum - 1].start + chapterLength
    ) {
      textDraw();
    } else {
      // console.log('no-text');
      document.documentElement.style.setProperty("--swipeX", "".concat(0, "%"));
    }
  } else {
    // back
    if (
      stageCutNum >= chapters[chapterNum - 1].start &&
      stageCutNum <= chapters[chapterNum - 1].start + chapterLength
    ) {
      textDraw();
    } else if (
      chapterNum > 1 &&
      stageCutNum < chapters[chapterNum - 1].start &&
      stageCutNum >= chapters[chapterNum - 2].start &&
      stageCutNum <= chapters[chapterNum - 2].start + chapterLength
    ) {
      chapterNum--;
      textDraw();
    } else {
      // console.log('no-text');
      document.documentElement.style.setProperty("--swipeX", "".concat(0, "%"));
    }
  }

  function textDraw() {
    if (chapterNum > 7) return;

    let obj, process, value;
    const cgap = 0.05;
    const chapterPointBefore = chapterPoint - cgap;
    const chapterPointAfter = chapterPoint + cgap;

    obj = document.querySelector(".chapter-" + chapterNum);
    process = (stageCutNum - chapters[chapterNum - 1].start) / chapterLength;

    // console.log(stageCutNum);

    gsap.killTweensOf(document.documentElement);
    gsap.killTweensOf(obj);
    // console.log((Math.abs((0.5 - process*10))), process, chapterLength, stageCutNum - chapters[chapterNum - 1].start);
    if (process === 0 || process === 1) {
      obj.style.display = "none";
      chapterProgress = 0;
      document.documentElement.style.setProperty("--swipeX", "".concat(0, "%"));
      if (process === 0) {
        if (obj.classList.contains("right")) {
          obj.style.opacity = 0;
          obj.style.filter = "blur(6px)";
          obj.style.transform = "translate(10%, -35%), scale(.4)";
        } else {
          obj.style.opacity = 0;
          obj.style.filter = "blur(6px)";
          obj.style.transform = "translate(-10%, -35%), scale(.4)";
        }
      } else if (process === 1) {
        obj.style.opacity = 1;
        obj.style.filter = "blur(8px)";
        if (obj.classList.contains("right")) {
          obj.style.transform = "translate(-120%, -190%), scale(6)";
        } else {
          obj.style.transform = "translate(120%, -190%), scale(6)";
        }
      }
    } else if (process < chapterPointBefore) {
      value = easeOutCubic(process / chapterPointBefore);
      chapterProgress = value * (obj.classList.contains("right") ? 1 : -1);
      document.documentElement.style.setProperty(
        "--swipeX",
        "".concat(focusSlide(), "%")
      );

      // console.log('1', process, process / chapterPointBefore, value);

      obj.style.display = "block";
      obj.style.opacity = value;
      obj.style.filter = "blur(" + (8 * (1 - value) - 2) + "px)";
      if (obj.classList.contains("right")) {
        // right
        obj.style.transform =
          "translate(" +
          10 * (1 - value) +
          "%, -" +
          (35 + 15 * value) +
          "%) scale(" +
          (0.4 + 0.6 * value) +
          ")";
      } else {
        // left
        obj.style.transform =
          "translate(" +
          -10 * (1 - value) +
          "%, -" +
          (35 + 15 * value) +
          "%) scale(" +
          (0.4 + 0.6 * value) +
          ")";
      }
    } else if (process > chapterPointAfter) {
      value = easeInQuart(
        (process - chapterPointAfter) / (1 - chapterPointAfter)
      );
      chapterProgress =
        (1 - value) * (obj.classList.contains("right") ? 1 : -1);
      document.documentElement.style.setProperty(
        "--swipeX",
        "".concat(focusSlide(), "%")
      );

      // console.log('2', process, process / chapterPointAfter, value);
      obj.style.display = "block";
      obj.style.opacity = 1;
      obj.style.filter = "blur(" + (10 * value - 2) + "px)";
      if (obj.classList.contains("right")) {
        // right
        obj.style.transform =
          "translate(" +
          -120 * value +
          "%, -" +
          (50 + 140 * value) +
          "%) scale(" +
          (1 + 5 * value) +
          ")";
      } else {
        // left
        obj.style.transform =
          "translate(" +
          120 * value +
          "%, -" +
          (50 + 140 * value) +
          "%) scale(" +
          (1 + 5 * value) +
          ")";
      }
    } else {
      value = 1;
      chapterProgress = obj.classList.contains("right") ? 1 : -1;
      document.documentElement.style.setProperty(
        "--swipeX",
        "".concat(focusSlide(), "%")
      );

      obj.style.display = "block";
      obj.style.opacity = 1;
      obj.style.filter = "blur(" + 0 + "px)";
      obj.style.transform =
        "translate(" + 0 + "%, -" + 50 + "%) scale(" + 1 + ")";
    }

    if (process === 1) chapterNum++;
  }
}

async function preLoad() {
  for (let i = 1; i <= monMaxCount; i++) {
    const src =
      "../static/images/mon/webp/0" +
      (i < 100 ? (i < 10 ? "00" + i : "0" + i) : i) +
      ".webp";
    loadArr.push(src);
    await addImg(i - 1);
  }
  for (let k = 1; k <= fogLength; k++) {
    const src = "../static/images/fog/1000/" + (k < 10 ? "0" + k : k) + ".webp";
    loadArr.push(src);
    await addImg(monMaxCount + k - 1);
  }
  for (let j = 1; j <= 127; j++) {
    const src =
      "../static/images/world/" +
      stageName +
      "/webp/" +
      (j < 1000 ? (j < 100 ? (j < 10 ? "000" + j : "00" + j) : "0" + j) : j) +
      ".webp";
    const lowerSrc =
      "../static/images/world/" +
      stageName +
      "/webp/low/" +
      (j < 1000 ? (j < 100 ? (j < 10 ? "000" + j : "00" + j) : "0" + j) : j) +
      ".webp";
    if (!isMobile) {
      loadArr.push(src);
      await addImg(monMaxCount + fogLength + j - 1);
    } else {
      loadArr.push(lowerSrc);
      await addImg(monMaxCount + fogLength + j - 1);
    }

    stageImgSrcArr.push(lowerSrc);
    stageImgSrcHighArr.push(src);
  }
  for (let j = 128; j <= 254; j++) {
    const src =
      "../static/images/world/" +
      stageName +
      "/webp/" +
      (j < 1000 ? (j < 100 ? (j < 10 ? "000" + j : "00" + j) : "0" + j) : j) +
      ".webp";
    const lowerSrc =
      "../static/images/world/" +
      stageName +
      "/webp/low/" +
      (j < 1000 ? (j < 100 ? (j < 10 ? "000" + j : "00" + j) : "0" + j) : j) +
      ".webp";
    if (!isMobile) {
      loadArr.push(src);
      await addImg(monMaxCount + fogLength + j - 1);
    } else {
      loadArr.push(lowerSrc);
      await addImg(monMaxCount + fogLength + j - 1);
    }

    stageImgSrcArr.push(lowerSrc);
    stageImgSrcHighArr.push(src);
  }
  for (let j = 255; j <= 381; j++) {
    const src =
      "../static/images/world/" +
      stageName +
      "/webp/" +
      (j < 1000 ? (j < 100 ? (j < 10 ? "000" + j : "00" + j) : "0" + j) : j) +
      ".webp";
    const lowerSrc =
      "../static/images/world/" +
      stageName +
      "/webp/low/" +
      (j < 1000 ? (j < 100 ? (j < 10 ? "000" + j : "00" + j) : "0" + j) : j) +
      ".webp";
    if (!isMobile) {
      loadArr.push(src);
      await addImg(monMaxCount + fogLength + j - 1);
    } else {
      loadArr.push(lowerSrc);
      await addImg(monMaxCount + fogLength + j - 1);
    }

    stageImgSrcArr.push(lowerSrc);
    stageImgSrcHighArr.push(src);
  }
  for (let j = 382; j <= stageSceneMaxCount / 2; j++) {
    const src =
      "../static/images/world/" +
      stageName +
      "/webp/" +
      (j < 1000 ? (j < 100 ? (j < 10 ? "000" + j : "00" + j) : "0" + j) : j) +
      ".webp";
    const lowerSrc =
      "../static/images/world/" +
      stageName +
      "/webp/low/" +
      (j < 1000 ? (j < 100 ? (j < 10 ? "000" + j : "00" + j) : "0" + j) : j) +
      ".webp";
    if (!isMobile) {
      loadArr.push(src);
      await addImg(monMaxCount + fogLength + j - 1);
    } else {
      loadArr.push(lowerSrc);
      await addImg(monMaxCount + fogLength + j - 1);
    }

    stageImgSrcArr.push(lowerSrc);
    stageImgSrcHighArr.push(src);
  }
}

function addImg(num) {
  const img = document.createElement("img");
  img.onload = imgLoad;
  img.setAttribute("src", loadArr[num]);
  img.setAttribute("style", "opacity:0");
  img.setAttribute("data-index", num);
  document.querySelector(".cache").appendChild(img);
}

function imgLoad(e) {
  const img = e.currentTarget;
  const x = img.getAttribute("data-index");
  img.setAttribute("style", "opacity:1");
  loadCount++;
  if (!loadingFlag) {
    updateLoading();
  }

  if (loadArr[x].indexOf("mon") !== -1) {
    monArr[x] = img;
  } else if (loadArr[x].indexOf("fog") !== -1) {
    fogArr[x - monMaxCount] = img;
  } else if (loadArr[x].indexOf("webp/low") !== -1) {
    stageContainerLowerArr[x - monMaxCount - fogLength] = img;
  } else if (loadArr[x].indexOf("stage1") !== -1) {
    stageContainerArr[x - monMaxCount - fogLength] = img;
    if (x - monMaxCount === 0) stageDraw(stageContainerArr, 0);
  }
}
function updateLoading() {
  if (document.querySelector(".loading-new")) {
    const percent = (loadCount / 678) * 100;
    document.querySelector(".loading-new .count").innerText =
      parseInt(percent) + "%";
  }
  if (loadCount === loadArr.length) {
    console.log("Complete");
    loadingFlag = true;

    document.querySelector(".mon-wrapper").style.opacity = 0;
    monBounce();
    setTimeout(loadingComp, 500);
    for (
      let j = stageSceneMaxCount / 2 + 1;
      j <= stageSceneMaxCount * 0.75;
      j++
    ) {
      const src =
        "../static/images/world/" +
        stageName +
        "/webp/" +
        (j < 1000 ? (j < 100 ? (j < 10 ? "000" + j : "00" + j) : "0" + j) : j) +
        ".webp";

      const lowerSrc =
        "../static/images/world/" +
        stageName +
        "/webp/low/" +
        (j < 1000 ? (j < 100 ? (j < 10 ? "000" + j : "00" + j) : "0" + j) : j) +
        ".webp";
      if (!isMobile) {
        loadArr.push(src);
        addImg(monMaxCount + fogLength + j - 1);
      } else {
        loadArr.push(lowerSrc);
        addImg(monMaxCount + fogLength + j - 1);
      }

      stageImgSrcArr.push(lowerSrc);
      stageImgSrcHighArr.push(src);
    }
    for (let j = stageSceneMaxCount * 0.75 + 1; j <= stageSceneMaxCount; j++) {
      const src =
        "../static/images/world/" +
        stageName +
        "/webp/" +
        (j < 1000 ? (j < 100 ? (j < 10 ? "000" + j : "00" + j) : "0" + j) : j) +
        ".webp";

      const lowerSrc =
        "../static/images/world/" +
        stageName +
        "/webp/low/" +
        (j < 1000 ? (j < 100 ? (j < 10 ? "000" + j : "00" + j) : "0" + j) : j) +
        ".webp";
      if (!isMobile) {
        loadArr.push(src);
        addImg(monMaxCount + fogLength + j - 1);
      } else {
        loadArr.push(lowerSrc);
        addImg(monMaxCount + fogLength + j - 1);
      }

      stageImgSrcArr.push(lowerSrc);
      stageImgSrcHighArr.push(src);
    }
  }
}

function loadingComp() {
  document.querySelector(".cache").style.display = "none";

  gsap.to(".loading-new", {
    opacity: 0,
    duration: 1,
    onComplete: function () {
      document.querySelector(".loading-new").style.display = "none";
    },
  });
  gsap.fromTo(
    ".mon-wrapper",
    { opacity: 0 },
    {
      opacity: 1,
      duration: 1,
      delay: 1,
      onStart: () => {
        document.querySelector(".mon-wrapper").style.display = "block";
      },
    }
  );
  gsap.fromTo(
    ".btn.explore",
    { opacity: 0 },
    {
      opacity: 1,
      duration: 1,
      delay: 1,
      onStart: () => {
        document.querySelector(".btn.explore").style.display = "block";
      },
    }
  );

  document.querySelector(".page-content .section").style.opacity = 0;

  resizeCalc();
}

export const enterExplore = () => {
  if (document.querySelector(".btn.explore").classList.contains("clicked"))
    return;

  if (!bgm.played.length) {
    bgm.play();
    window.dispatchEvent(new CustomEvent("GAME_SOUND_MUTE"));
  }

  document.querySelector("html").classList.remove("fixed");
  document.querySelector("body").classList.remove("restart");
  document.querySelector(".btn.explore").classList.add("clicked");

  gsap.to(".btn.explore", {
    opacity: 0,
    duration: 2,
    onComplete: () => {
      document.querySelector(".btn.explore").style.display = "none";
      document.querySelector(".btn.explore").classList.remove("clicked");
    },
  });
  gsap.fromTo(
    "#header",
    { opacity: 0 },
    {
      opacity: 1,
      duration: 2,
      onStart: () => {
        document.querySelector("#header").style.display = "block";
      },
    }
  );
  gsap.set(".page-content .section", { opacity: 0 });
  gsap.fromTo(
    ".page-content .section1",
    { opacity: 0 },
    {
      opacity: 1,
      duration: 2,
      delay: 2,
      onStart: () => {
        document.querySelector(".section1 .wrapper").style.display = "block";
        document.querySelector(".section2 .wrapper").style.display = "block";
        document.querySelector(".section3 .wrapper").style.display = "block";
      },
    }
  );

  document.querySelector(".mon-wrapper").classList.add("enter");

  gsap.to(".section2", {
    scrollTrigger: {
      trigger: ".section2",
      start: "center bottom",
      end: "center bottom",
      toggleActions: "play none none reverse",
      // markers: true
    },
    opacity: 1,
    duration: 1,
    ease: "Cubic.easeOut",
  });
  gsap.to(".section3", {
    scrollTrigger: {
      trigger: ".section3",
      start: "center bottom",
      end: "center bottom",
      toggleActions: "play none none reverse",
      // markers: true
    },
    opacity: 1,
    duration: 1,
    ease: "Cubic.easeOut",
  });

  const monScaleTween = gsap.to(".mon", {
    scrollTrigger: {
      trigger: ".page-content .gap",
      endTrigger: ".stage",
      start: "top top",
      end: "top top",
      scrup: true,
      onComplete: () => {
        document.querySelector(".mon-cover").classList.add("expand");
      },
      onUpdate: (self) => {
        document.querySelector(".mon-cover").style.transform =
          "scale(" + (1 - 0.3 * self.progress) + ")";
      },
    },
  });

  bodyScrollBlock(false);
  stageDraw(stageContainerArr, 0);

  return () => {
    monScaleTween.kill();
  };
};

export function landingStage() {
  scrollTop(window, 0);
  document.querySelector(".page-content").style.display = "none";
  document.querySelector(".mon-cover").style.transform =
    "scale(" + (1 - 0.3) + ")";

  bodyScrollBlock(true);

  document.querySelector(".stage").classList.add("active");
  document.querySelector("html").classList.add("fixed");

  document.querySelector("#header .logo").classList.add("overlay");
  document.querySelector("#header .gnb-btn").classList.add("overlay");
  document.querySelector("#header .sound-btn").classList.add("overlay");

  document.querySelector(".mon-cover").classList.add("expand");
}

function resizeCalc() {
  // console.log(window.innerWidth);
  setUnitProperty();
  stageDraw(stageContainerArr, stageCutNum);
  fogDraw(stageCutNum);

  standardRatioW = monWidth / 1920 / (monWidth / window.innerWidth);
  standardRatioH = monHeight / 980 / (monHeight / window.innerHeight);

  // console.log(standardRatioW, standardRatioH);

  document.querySelector(".mon").style.transform =
    "translate(calc(-50% + var(--swipeX) * 2), -50%) scale(" +
    Math.max(standardRatioW, standardRatioH) +
    ")";
  document.querySelector(".mon .btn").style.transform =
    "scale(" + (1 + (1 - Math.max(standardRatioW, standardRatioH))) + ")";
}

function gnbInit() {
  const gnbs = document.querySelectorAll("#gnb a");
  for (const i in gnbs) {
    if (Object.hasOwnProperty.call(gnbs, i)) {
      const el = gnbs[i];
      el.addEventListener("click", (e) => {
        e.preventDefault();

        gsap.killTweensOf(tourPointObj);
        gsap.killTweensOf(".mon-wrapper");
        gsap.killTweensOf(".chapter-end .inner");
        gsap.killTweensOf(".chapter-end .footer");

        if (document.querySelector(".text-wrap .chapter-" + chapterNum))
          document.querySelector(
            ".text-wrap .chapter-" + chapterNum
          ).style.display = "none";

        document.querySelector(".gnb-btn").dispatchEvent(new Event("click"));
        // console.log(i, chapters[i].start, chapters[i].start + (chapterLength * chapterPoint));

        if (!document.querySelector(".stage").classList.contains("active"))
          landingStage();

        if (
          document.querySelector(".chapter-end").classList.contains("reset")
        ) {
          document.querySelector(".chapter-end").style.display = "none";
          document.querySelector(".chapter-end").classList.remove("reset");
        }
        document.querySelector(".mon-wrapper").style.opacity = 1;

        stageCutNum = chapters[i].start + chapterLength * chapterPoint;
        chapterNum = Number(i) + 1;
        // console.log(stageCutNum, chapterNum);
        stageDraw(stageContainerArr, stageCutNum);
        chapterDraw("front");
        document.querySelector(".fog").style.display = "none";

        autoPlayChk = false;
      });
    }
  }
}

export const initialize = async () => {
  if (initChk) return;
  initChk = true;

  monCanvas = document.querySelector(".mon canvas");
  monContext = monCanvas?.getContext("2d");
  stageCanvas = document.querySelector(".land canvas");
  stageContext = stageCanvas.getContext("2d");
  fogCanvas = document.querySelector(".fog canvas");
  fogContext = fogCanvas.getContext("2d");

  const mainContent = document.querySelector(".main-content");

  document.querySelector(".btn.explore").style.display = "none";
  document.querySelector(".fog").style.display = "none";
  document.querySelector(".section1 .wrapper").style.display = "none";
  document.querySelector(".section2 .wrapper").style.display = "none";
  document.querySelector(".section3 .wrapper").style.display = "none";

  document
    .querySelector(".chapter-end .btn")
    .addEventListener("click", function () {
      if (document.querySelector(".chapter-end").classList.contains("reset")) {
        document.querySelector(".chapter-end").classList.remove("reset");
        resetStage();
      }
    });

  window.addEventListener("resize", resizeCalc);

  document.querySelector(".gnb-btn").addEventListener("click", (e) => {
    e.preventDefault();

    if (document.querySelector(".gnb-btn").classList.contains("active")) {
      if (!document.querySelector(".stage").classList.contains("active"))
        bodyScrollBlock(false);
      document.querySelector("body").classList.remove("gnb-hidden");
    } else {
      bodyScrollBlock(true);
      document.querySelector("body").classList.add("gnb-hidden");
    }

    document.querySelector(".logo").classList.toggle("active");
    document.querySelector(".gnb-btn").classList.toggle("active");
    document.querySelector(".gnb-wrap").classList.toggle("active");
  });

  document.querySelector(".sound-btn").addEventListener("click", (e) => {
    window.dispatchEvent(new Event("GAME_SOUND_MUTE"));
  });

  preLoad();

  gnbInit();

  resizeCalc();

  scrollTop(window, 0);

  bodyScrollBlock(true);

  for (const i in document.querySelectorAll(".text-wrap .text")) {
    if (
      Object.hasOwnProperty.call(
        document.querySelectorAll(".text-wrap .text"),
        i
      )
    ) {
      const element = document.querySelectorAll(".text-wrap .text")[i];
      element.style.display = "none";
    }
  }

  mainContent.addEventListener("wheel", function (e) {
    if (
      !document.querySelector(".stage").classList.contains("active") ||
      autoPlayChk ||
      document.querySelector(".chapter-end").classList.contains("reset")
    )
      return;

    speed = e.deltaY;

    goToStep();
  });

  mainContent.addEventListener("touchstart", handleTouchStart);
  mainContent.addEventListener("touchmove", handleTouchMove);
  mainContent.addEventListener("touchend", handleTouchEnd);

  if (isMobile) {
    moImg.addEventListener("load", canvasDraw);
  }
};

function goToStep() {
  moveSpeed();
}

function resetStage() {
  stageCutNum = 0;
  chapterNum = 1;

  gsap.to("#content", {
    opacity: 0,
    duration: 2,
    ease: "Sine.easeIn",
    onStart: () => {
      document.querySelector(".stage").classList.remove("active");

      document.querySelector("body").classList.add("restart");
    },
    onComplete: () => {
      document.querySelector("html").classList.remove("fixed");

      document.querySelector(".section1 .wrapper").style.display = "none";
      document.querySelector(".section2 .wrapper").style.display = "none";
      document.querySelector(".section3 .wrapper").style.display = "none";

      document.querySelector("#header .logo").classList.remove("overlay");
      document.querySelector("#header .gnb-btn").classList.remove("overlay");
      document.querySelector("#header .sound-btn").classList.remove("overlay");

      document.querySelector(".mon-wrapper").classList.remove("enter");
      document.querySelector(".mon-cover").classList.remove("expand");
      document.querySelector(".mon-cover").removeAttribute("style");

      document.querySelector(".chapter-end").style.display = "none";
      document.querySelector(".page-content").style.display = "block";

      document.querySelector("#header").style.display = "none";

      document.querySelector(".btn.explore").style.display = "none";

      document.querySelector(".fog").style.display = "none";

      stageDraw(stageContainerArr, stageCutNum);

      gsap.to("#content", {
        opacity: 1,
        duration: 2,
        ease: "Sine.easeOut",
        onComplete: () => {
          loadingComp();
        },
      });
    },
  });
}

let lastTouch, touchStartEvent;
let startPosition = {};
let dragging = false;

const handleTouchStart = function (e) {
  touchStartEvent = e;
  startPosition = { x: e.touches[0].clientX, y: e.touches[0].clientY };
  dragging = true;
};

const handleTouchMove = function (e) {
  let touch = { x: e.touches[0].clientX, y: e.touches[0].clientY };
  if (!lastTouch) {
    lastTouch = { x: touch.x, y: touch.y };
  }
  handleMove(touch.x, touch.y, touch.x - lastTouch.x, touch.y - lastTouch.y);
  lastTouch = { x: touch.x, y: touch.y };
};

const handleTouchEnd = function (e) {
  lastTouch = null;

  dragging = false;
};

const handleMove = function (x, y, xMove, yMove) {
  if (!dragging) {
    return;
  }

  if (
    !document.querySelector(".stage").classList.contains("active") ||
    autoPlayChk ||
    document.querySelector(".chapter-end").classList.contains("reset")
  )
    return;

  if (yMove === 0) return;
  speed = -yMove;

  goToStep();
};

// 스크롤 막는 함수
let scrollHeight = 0;
function bodyScrollBlock(chk) {
  if (chk) {
    scrollHeight = scrollTop(document);
    document.querySelector("body").classList.add("hidden");
    document.querySelector("body").style.position = "fixed";
    document.querySelector("body").style.top = -scrollHeight + "px";
  } else {
    document.body.removeAttribute("style");
    document.querySelector("body").classList.remove("hidden");
    scrollTop(document, scrollHeight);
  }
}

function scrollTop(el, value) {
  if (value === undefined) {
    return el.pageYOffset;
  } else {
    if (el === window || el.nodeType === 9) {
      el.scrollTo(el.pageXOffset, value);
    } else {
      el.pageYOffset = value;
    }
  }
}

function easeOutCubic(x) {
  return 1 - Math.pow(1 - x, 3);
}

function easeInQuart(x) {
  return x * x * x * x;
}
