import { memo } from "react";
import { enterExplore } from "../../utils/common";

const ExploreBtn = () => {
  return (
    <div className="btn-area">
      <button
        onClick={() => {
          enterExplore();
        }}
        className="btn explore"
      >
        <span>explore</span>
      </button>
    </div>
  );
};

export default memo(ExploreBtn);
