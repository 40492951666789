import { memo } from "react";

const StartChapter = () => {
  return (
    <div className="wrapper">
      <div className="land">
        <canvas></canvas>
      </div>
      <div className="text-wrap">
        <div className="text chapter-1">
          <div className="inner">
            <div className="headline">
              <span>CHAPTERS 01 : LAND</span>
            </div>
            <strong>Mysterious Land</strong>
            <span>
              You might wonder what we are doing here... <br className="pc" />{" "}
              but relax, sit tight! This vast nothing before you is{" "}
              <br className="pc" /> called Zin— a faraway land from Another
              City.
              <br />
              In Another World exist many different <br className="pc" />{" "}
              geographical landscapes where infinite possibilities{" "}
              <br className="pc" /> remain undiscovered.
              <br />
              We are about to begin tales of great mystery.
            </span>
          </div>
        </div>
        <div className="text chapter-2 right">
          <div className="inner">
            <div className="headline">
              <span>CHAPTERS 02 : AVATAR</span>
            </div>
            <strong>What would you like to live as?</strong>
            <span>
              You can be a human or an animal. You can even be a{" "}
              <br className="pc" /> human in the form of an animal. In Another
              World, <br className="pc" /> everyone lives harmoniously in
              different forms. <br className="pc" />
              Feel free to choose a shape of life you desire to take on!
            </span>
          </div>
        </div>
        <div className="text chapter-3">
          <div className="inner">
            <div className="headline">
              <span>CHAPTERS 03 : ANOTHER TOOL</span>
            </div>
            <strong>Creation is privilege!</strong>
            <span>
              We will set into the world for creators who make our space even
              more boundless.
              <br />
              In Another World, programmers and creators without coding
              knowledge alike can build anything.
            </span>
          </div>
        </div>
        <div className="text chapter-4 right">
          <div className="inner">
            <div className="headline">
              <span>CHAPTERS 04 : PLACE</span>
            </div>
            <strong>A space that completes us</strong>
            <span>
              We offer everything needed for a fulfilling <br className="pc" />
              gaming experience in Another World and are dedicated
              <br className="pc" />
              to creating valuable and positive projects.
            </span>
          </div>
        </div>
        <div className="text chapter-5">
          <div className="inner">
            <div className="headline">
              <span>CHAPTERS 05 : OUR ECOSYSTEM</span>
            </div>
            <strong>Our World's Flow</strong>
            <span>
              Step into the rhythm of Another World, where community values
              shape every aspect. Explore our vibrant ecosystem, where every
              player's journey enriches our shared adventure.
            </span>
          </div>
        </div>
        <div className="text chapter-6 right">
          <div className="inner">
            <div className="headline">
              <span>CHAPTERS 06 : GAME</span>
            </div>
            <strong>Crafting Entertainment</strong>
            <span>
              Games offer a unique lens through which we can explore the world
              and its challenges. At Another World, our mission is to create
              diverse, experimental, and above all, entertaining gaming
              experiences. Join us as we strive to make the world a better place
              through the power of play.
            </span>
          </div>
        </div>
        <div className="text chapter-7">
          <div className="inner">
            <div className="headline">
              <span>CHAPTERS 07 : VISION</span>
            </div>
            <strong>Gaming Toward Tomorrow</strong>
            <span>
              Our vision sees gaming as a catalyst for progress, where
              technology fuels boundless creativity. Join us as we shape a
              brighter future, pushing boundaries and fostering innovation
              through play.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(StartChapter);
